// Polyfills
import "core-js/stable";
import "regenerator-runtime/runtime";
import "mdn-polyfills/Element.prototype.closest";

// Modules
import "bootstrap-sass/assets/javascripts/bootstrap";
import Clipboard from "clipboard";
import Swiper from "swiper/dist/js/swiper";
import "jquery-unveil";
import moment from "moment-timezone";
import MobileDetect from "mobile-detect";

// Vendors
import "./vendor/modernizr-custom.min";
import browserUpdate from "./vendor/browser-update/update.npm.full.js"; // ce fichier est copié des node_modules puis modifié à chaque build: https://github.com/browser-update/browser-update/issues/444

// Angular
import "./angular-front/st";

// Custom modules
import "./modules/alert";
import autocomplete from "./modules/autocomplete";
import "./modules/download-file-button-loading";
import "./modules/follow-scroll";
import "./modules/header-canvas";
import "./modules/photoswipe";
import "./modules/st-select";
import "./modules/timeline";

// Custom pages
import "./pages/conversation";
import "./pages/offer";
import "./pages/sponsorship";

// expose variables globally to use them in templates
global.autocompleteWidget = autocomplete;

moment.locale("fr");
moment.tz.setDefault("Europe/Paris");

const voidFunction = () => {
  $(document).ready(() => {
    /** Mobile detect **/
    const md = new MobileDetect(window.navigator.userAgent);
    if (md.phone() !== null) {
      $("html").addClass("device-detected phone");
    } else {
      $("html").addClass("device-detected");
    }

    /** Alert sur les anciens navigateurs **/
    browserUpdate({
      required: {
        i: 999, // IE (do not support any IE versions)
      },
      insecure: true, // notify all insecure browsers
      newwindow: true,
      style: "corner",
      l: "fr", // language
      reminder: 0, // hours
      noclose: false,
      reminderClosed: 0.1, // hours
      no_permanent_hide: true,
    });

    /** Toggle backdrop on filter is opened **/
    $(".dropdown.filter")
      .on("show.bs.dropdown", () => {
        $("body").addClass("filter-opened");
      })
      .on("hide.bs.dropdown", () => {
        $("body").removeClass("filter-opened");
      });

    /** ToolTips **/
    $('[data-toggle="tooltip"]').tooltip();

    /** Auto select content field **/
    $('input[rel="autoselect"]').click((e) => {
      // use .focus() for readonly input
      $(e.target).focus().select();
    });

    /** Utilise le plugin clipboard.js pour copier un texte en 1 clic. **/
    const clipboardSuccess = (e) => {
      const $textContainer = $(e.trigger).find(".copy-btn-text");
      const initialText = $textContainer.text();
      $textContainer.text("Copié !");

      setTimeout(() => {
        $textContainer.addClass("transitionning");
        setTimeout(() => {
          $textContainer.text(initialText).removeClass("transitionning");
        }, 300);
      }, 2000);

      e.clearSelection();
    };

    const clipboardError = (e) => {
      snackbarNotification(`Erreur : ${e}`);
    };

    const clipboard = new Clipboard(".btn-copy");
    clipboard.on("success", clipboardSuccess);
    clipboard.on("error", clipboardError);

    const clipboardHtml = new Clipboard(".btn-copy-html", {
      text: (trigger) => {
        // based on your fiddle, you may need to replace this selector, too.
        const target = $(trigger).data("clipboard-target");
        const htmlBlock = document.querySelector(target);
        return htmlBlock.innerHTML;
      },
    });
    clipboardHtml.on("success", clipboardSuccess);
    clipboardHtml.on("error", clipboardError);

    /** Snackbar notification **/
    const snackbarNotification = (text) => {
      const $snackbar = $("#snackbar");

      $snackbar.addClass("show").find("p").text(text);

      setTimeout(() => {
        $snackbar.removeClass("show");
      }, 3000);
    };

    /** Lazy loading images **/
    $("img").unveil(200);

    /** Scroll to **/
    $("a.smooth").click((e) => {
      const _self = $(e.target).hasClass("smooth") ? e.target : $(e.target).closest("a.smooth")[0];

      // on vérifie que le lien href est bien identique à l'url de la page actuelle
      if (
        window.location.pathname.replace(/^\//, "") === _self.pathname.replace(/^\//, "") &&
        window.location.hostname === _self.hostname
      ) {
        let target = $(_self.hash);
        target = target.length ? target : $(`[name="${_self.hash.slice(1)}"]`);

        if (target.length) {
          const targetMarginTop = parseInt(target.css("marginTop"));
          let topFixedElement = 0;

          // on vérifie que la navbar est présente et fixée en haut
          const $navbar = $("nav.navbar");
          if ($navbar.length > 0 && $navbar.css("position") === "fixed") {
            topFixedElement = 74;
          }

          const $stickyNav = $("ul.nav-sticky");
          if ($stickyNav.length > 0 && $stickyNav.css("position") === "sticky") {
            topFixedElement = $stickyNav.outerHeight();
          }

          $("html,body").animate(
            {
              scrollTop: target.offset().top - targetMarginTop - topFixedElement,
            },
            500
          );
          return false;
        }
      }
    });

    /** More Details behavior **/
    $(".expandable").each((i, el) => {
      const $this = $(el);
      const $thisContent = $(".expanded-content", el).height();
      const lineHeightParsePx = parseInt($this.css("line-height"), 10);
      const lines = $(".expandable-content", el).attr("data-preview-lines");

      $(".expandable-content", el)
        .css("height", lineHeightParsePx * lines)
        .attr("data-min-height", lineHeightParsePx * lines);

      if ($thisContent <= parseFloat(lineHeightParsePx * lines)) $this.addClass("expanded preview-too-long");
    });

    $(".expandable-trigger-more").click((e) => {
      e.preventDefault();
      const $this = $(e.target);
      const cls = ".expandable";

      if ($this.parent(cls).hasClass("expanded")) {
        $this
          .text($this.attr("data-more"))
          .parent(cls)
          .removeClass("expanded")
          .find(".expandable-content")
          .css("height", $this.parent(cls).find(".expandable-content").attr("data-min-height"));
      } else {
        $this
          .text($this.attr("data-less"))
          .parent(cls)
          .addClass("expanded")
          .find(".expandable-content")
          .css("height", $this.parent(cls).find(".expanded-content").height());
      }
    });

    // For inline expandable style
    $(".inline-expandable__trigger-more").click((e) => {
      e.preventDefault();
      $(e.target).parent(".inline-expandable__excerpt").hide().siblings(".inline-expandable__content").show();
    });

    /** Detect keyboard shown on mobile **/
    const _originalSize = $(window).height();
    $(window).resize(() => {
      if ($(window).height() < _originalSize - 150) {
        $("body").addClass("keyboard-shown");
      } else {
        $("body").removeClass("keyboard-shown");
      }
    });

    /** Force placeholder always visible for ui-select angular module when multiple choices (used with past & next countries) **/
    // on loop chaque seconde tant qu'on a pas atteint la fonction (le champ tags n'est peut être pas encore généré par angular)
    if ($(".tags-input-group").length > 0 || $(".tags-input.select2").length > 0) {
      const waitAngular = setInterval(() => {
        const $uiSelectTags = $(".tags-input.select2");

        // si un champ tags existe
        if ($uiSelectTags.length > 0) {
          clearInterval(waitAngular);

          // on load (first time)
          $uiSelectTags.each((i, el) => {
            const $searchInput = $(el).find(".ui-select-search");

            if ($searchInput.attr("placeholder") === "") {
              $searchInput.attr("placeholder", $(el).find(".ui-select-match").attr("placeholder"));
            }
          });

          // on input tags change
          $uiSelectTags.find(".ui-select-match").bind("DOMSubtreeModified", (e) => {
            const $searchInput = $(e.target).parent().find(".ui-select-search");

            if ($searchInput.attr("placeholder") === "") {
              $searchInput.attr("placeholder", $(e.target).attr("placeholder"));
            }
          });
        }
      }, 1000);
    }

    /** Swiper **/
    const initializeSwipers = () => {
      const swiperPhotos = ".swiper-container";
      const $swiperPhotos = $(swiperPhotos);
      if ($swiperPhotos.length > 0) {
        const initializeSwiper = () => {
          $swiperPhotos.each((i, thisSwipper) => {
            const $thisSwiper = $(thisSwipper);

            if (
              $thisSwiper.css("display") === "none" ||
              $thisSwiper.closest(".reinitSwipersOnCollapseShown").css("display") === "none"
            ) {
              return;
            }

            let swiper;

            if ($thisSwiper.hasClass("swiper-initialized") === false) {
              const options = $thisSwiper.attr("data-options");
              swiper = options ? new Swiper(thisSwipper, JSON.parse(options)) : new Swiper(thisSwipper);
              $thisSwiper.addClass("swiper-initialized");
            } else {
              swiper = $thisSwiper[0].swiper;
            }

            // track the horizontal scroll event when the first scroll is fired.
            const dataLayerPushSwipe = $thisSwiper.data("layer-push-swipe");
            if (typeof dataLayerPushSwipe !== typeof undefined && dataLayerPushSwipe !== false) {
              $thisSwiper.data("layer-pushed-swipe", false);

              swiper.on("progress", (progress) => {
                if (progress > 0 && $thisSwiper.data("layer-pushed-swipe") === false) {
                  $thisSwiper.data("layer-pushed-swipe", true);

                  window.dataLayer.push(dataLayerPushSwipe);
                }
              });
            }

            /**
             * Lazy load images.
             *
             * Initialisation : on charge l'image active
             * Ensuite : on charge N+1, N-1, N+2, N-2
             *
             * À chaque changement de slide :
             * - on regarde : est-ce que, par rapport à ma nouvelle slide active, N+1, N-1, N+2, N-2 sont chargées ?
             * - si l'un d'eux ne l'est pas, on le charge.
             */
            if ($thisSwiper.find(".lazy:not(.loaded)").length > 0) {
              const lazyLoading = (imageEl) => {
                // La class is-loading permet d'éviter de charger 2 fois la même image.
                // On ne déclenche pas le trigger 'image:loaded' car il sera déclenché quand l'image aura fini de charger.
                if ($(imageEl).hasClass("is-loading") === true) {
                  return;
                }

                if ($(imageEl).hasClass("loaded") === false) {
                  $(imageEl).addClass("is-loading");

                  const imageUrl =
                    window.innerWidth <= 767 && $(imageEl).attr("data-src-sm") !== undefined
                      ? $(imageEl).attr("data-src-sm")
                      : $(imageEl).attr("data-src");

                  // prevent: 404 -> /offre/undefined
                  if (imageUrl === undefined) {
                    $(imageEl).removeClass("is-loading");
                    $thisSwiper.trigger("image:loaded");
                    return;
                  }

                  $(`<img src='${imageUrl}'/>`)
                    .on("load", () => {
                      $(this).remove(); // prevent memory leaks

                      $(imageEl)
                        .css({
                          backgroundImage: `url(${imageUrl})`,
                        })
                        .addClass("loaded")
                        .removeClass("is-loading");

                      $thisSwiper.trigger("image:loaded");
                    })
                    .on("error", () => {
                      $(this).remove(); // prevent memory leaks

                      const index = $(imageEl)
                        .addClass("loaded")
                        .closest(".swiper-slide")
                        .attr("data-swiper-slide-index");
                      swiper.removeSlide(index);

                      $thisSwiper.trigger("image:loaded");
                    });
                } else {
                  $thisSwiper.trigger("image:loaded");
                }
              };

              // étape 1 : chargement de l'image en cours puis déclenchement de lazyLoading sur autres images (voir étape 2).
              const $imageEl = $thisSwiper.find(".swiper-slide-active > .lazy");
              lazyLoading($imageEl);

              // étape 2 : chargement des images autour de la slide active (± 2 images)
              // /!\ attention on ne support pas les swipers infinis (circulaires) --> on ne va loader que les images précédentes et suivantes sans aller chercher l'image de fin si on est en position 0 par exemple.
              $thisSwiper.on("image:loaded", () => {
                // si au moins une image est en train de charger, on ne déclenche pas le chargement d'autres images.
                if ($thisSwiper.find(".swiper-slide > .is-loading").length > 0) {
                  return;
                }

                const $activeSlide = $thisSwiper.find(".swiper-slide-active");
                const images = [];

                const prevSlides = $activeSlide.prevAll().slice(0, 2);
                const nextSlides = $activeSlide.nextAll().slice(0, 2);
                // on va charger dans l'ordre : N+1, N-1, N+2, N-2, etc.
                const slides = [nextSlides[0], prevSlides[0], nextSlides[1], prevSlides[1]];

                slides.forEach((slide) => {
                  const image = $(slide).find(".lazy:not(.loaded)");
                  if (image.length > 0) {
                    images.push(image);
                  }
                });

                if (images.length > 0) {
                  lazyLoading(images[0]);
                }
              });

              // étape 3 : on relance la machine à chaque changement de slide
              swiper.on("slideChangeTransitionStart", function () {
                const $imageEl = $thisSwiper.find(".swiper-slide-active > .lazy");
                if ($imageEl.length > 0) {
                  lazyLoading($imageEl[0]);
                }
              });
            }
          });
        };

        initializeSwiper();

        $(window).on("resize", () => {
          initializeSwiper();
        });
      }
    };

    initializeSwipers();

    // Cet event permet de réinitialiser les swipers de la page après un rendu dynamique
    const createNewEvent = (eventName) => {
      let event;
      if (typeof Event === "function") {
        event = new Event(eventName);
      } else {
        event = document.createEvent("Event");
        event.initEvent(eventName, true, true);
      }
      return event;
    };

    window.customEventInitSwiper = createNewEvent("customEventInitSwiper");
    document.body.addEventListener(
      "customEventInitSwiper",
      () => {
        initializeSwipers();
      },
      false
    );
    // à utiliser de cette manière :
    // if (window.customEventInitSwiper) {
    //     document.body.dispatchEvent(window.customEventInitSwiper);
    // }

    $(".reinitSwipersOnCollapseShown").on("shown.bs.collapse", () => {
      if (window.customEventInitSwiper) {
        document.body.dispatchEvent(window.customEventInitSwiper);
      }
    });

    /** Modal with iframe (stop video on close) **/
    $("body").on("hidden.bs.modal", (e) => {
      $(e.target)
        .find("iframe")
        .each((index, iframe) => {
          $(iframe).attr("src", $(iframe).attr("src"));
        });
    });
  });
};

export default voidFunction.call(window);
