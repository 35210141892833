const voidFunction = () => {
  $(document).ready(() => {
    const $select = $(".st-select--styled");
    const $list = $(".st-select--options");
    const $listItems = $list.children("li");

    $select.click((e) => {
      e.stopPropagation();
      const $self = $(e.target).hasClass("st-select--styled") ? $(e.target) : $(e.target).closest(".st-select--styled");

      $self.toggleClass("active");
    });

    $listItems.click((e) => {
      e.stopPropagation();
      const $self = $(e.target).is("li") ? $(e.target) : $(e.target).closest("li");

      // update the select value with selected one
      $select.removeClass("active").find(".st-select--styled-text").text($self.text());

      // redirect to offer page
      if ($self.attr("data-slug") !== window.location.pathname) {
        window.location = $self.attr("data-slug");
      }
    });

    $(document).click(() => {
      $select.removeClass("active");
    });
  });
};

export default voidFunction.call(window);
