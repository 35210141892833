import Typed from "typed.js";

const voidFunction = () => {
  $(document).ready(() => {
    /** Chat: form new post modal behavior **/
    const $modalCta = $("#ctaNewPost");
    if ($modalCta.length > 0) {
      const $modal = $("#postModal");
      const $textArea = $modal.find("textarea");
      const $modalFooter = $modal.find(".cdv-modal-footer");
      const overlay = "#main-overlay";
      const $overlay = $(overlay);

      const closeModal = () => {
        $modal.removeClass("showContent").find(".cdv-modal-content").css("visibility", "hidden");
        $("body").removeClass("modal-open");
        $overlay.removeAttr("style");
        $modal.removeClass("shown").css({
          left: $modalCta.offset().left,
          opacity: 0,
        });
        setTimeout(() => {
          $modal.removeClass("effect fixed").removeAttr("style");
        }, 200);
      };

      // Submit button disabled/enabled state
      const submitState = () => {
        if ($textArea.val() !== "") $modal.find('[type="submit"]').prop("disabled", false);
        else $modal.find('[type="submit"]').prop("disabled", true);
      };

      // Update height real time
      const resizeTextarea = () => {
        $textArea.css({
          height: $modalFooter.offset().top - $textArea.offset().top,
        });
        $textArea.height($textArea[0].scrollHeight);
      };

      // Open modal action
      $modalCta.on("click", () => {
        if (!$modal.hasClass("fixed")) {
          $overlay.css({
            opacity: 1,
            "z-index": 1000,
          });
          const offset = $modalCta.offset();
          const posY = offset.top - $(window).scrollTop();
          const posX = offset.left - $(window).scrollLeft();
          $modal
            .css({
              height: $modalCta.outerHeight(),
              width: $modalCta.width(),
              top: posY,
              left: posX,
            })
            .addClass("fixed");
          setTimeout(() => {
            $modal.addClass("effect shown").css({
              opacity: 1,
            });
          }, 10);
          setTimeout(() => {
            $modal.addClass("showContent");

            // Use bootstrap class to prevent scroll
            $("body").addClass("modal-open");

            // init textarea height
            resizeTextarea();

            // Focus textarea
            $textArea.focus();
          }, 200);
        }
      });

      // Close modal action
      $(document).on("click", `${overlay}, .cancelPostModal`, () => {
        if ($textArea.val() !== "") {
          if (window.confirm("Etes-vous sûre de vouloir supprimer ce message ?")) {
            closeModal();
          }
        } else {
          closeModal();
        }
      });

      // Init some functions
      submitState();
      resizeTextarea();

      // When textArea change, check some functions
      $textArea.keyup(() => {
        submitState();
        resizeTextarea();
      });
    }

    // Usefull for comment textarea resize
    if ($("textarea").hasClass("autoResize")) {
      const $txt = $(".autoResize");

      $txt.on("keyup", (e) => {
        const $self = $(e.target);

        if (e.target.value !== "") {
          $self.parentsUntil("form").find('[type="submit"]').show();
        } else {
          $self.parentsUntil("form").find('[type="submit"]').hide();
        }

        if (!$self.prop("scrollTop")) {
          do {
            var b = $self.prop("scrollHeight");
            const h = $self.height();
            $self.height(h - 6);
          } while (b && b !== $self.prop("scrollHeight"));
        }
        $self.height($self.prop("scrollHeight") - 6);
      });
    }

    /** Feed: typed.js animation
     * @readme https://github.com/mattboldt/typed.js/blob/master/README.md#customization
     **/
    if ($("#typedJs").length > 0) {
      new Typed("#typedJs", {
        stringsElement: "#typedStrings",
        // typing speed
        typeSpeed: 50,
        // time before typing starts
        startDelay: 0,
        // time before backspacing
        backDelay: 1000,
        // show cursor
        showCursor: true,
        // character for cursor
        cursorChar: "|",
        // loop
        loop: true,
        // false = infinite
        loopCount: false,
      });
    }

    /** Feed: Show more & URL hash **/
    if ($(".offer-conversation #items").length > 0) {
      // Show more posts button
      const $showMore = $("#showMore");

      const showAllFeed = () => {
        $(".cardFeed").show();
        $showMore.hide();
      };

      $showMore.on("click", () => {
        showAllFeed();
      });

      // URL hash scroll to
      if (window.location.hash) {
        const hash = window.location.hash.substring(1);
        const $hash = $(`#${hash}`);

        // NB: when an element has "display: none;", his offset top is 0
        if ($hash.offset().top === 0) {
          showAllFeed(); // Show all
          $(window).scrollTop($hash.offset().top); // Scroll to the elem
        }
      }
    }
  });
};

export default voidFunction.call(window);
