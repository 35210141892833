class googlePlacesFactory {
  constructor($document, $q, $rootScope, $window) {
    let d = $q.defer();

    function onScriptLoad() {
      // Load client in the browser
      $rootScope.$apply(() => {
        d.resolve($window.google);
      });
    }

    // Create a script tag with googlePlaces as the source
    // and call our onScriptLoad callback when it
    // has been loaded

    let scriptTag = $document[0].createElement("script");
    scriptTag.type = "text/javascript";
    scriptTag.async = true;
    scriptTag.src = `https://maps.googleapis.com/maps/api/js?key=${window.googleApiKey}&language=${window.language}&libraries=places`;

    scriptTag.onreadystatechange = () => {
      if (this.readyState === "complete") onScriptLoad();
    };

    scriptTag.onload = onScriptLoad;

    let s = $document[0].getElementsByTagName("body")[0];
    s.appendChild(scriptTag);

    return {
      googlePlaces: () => d.promise,
    };
  }

  static serviceFactory($document, $q, $rootScope, $window) {
    googlePlacesFactory.instance = new googlePlacesFactory($document, $q, $rootScope, $window);
    return googlePlacesFactory.instance;
  }
}

googlePlacesFactory.serviceFactory.$inject = ["$document", "$q", "$rootScope", "$window"];
export default googlePlacesFactory;
