// Core Angular
import "angular";
import "angular-i18n/angular-locale_fr-fr.js";

// Angular dependencies
import "angular-animate";
import "ng-file-upload";
import "ui-select";

// Custom code
import avatarCtrl from "./controllers/avatar";
import profileCtrl from "./controllers/profile";
import gmapAutocomplete from "./directives/gmapAutocomplete";
import googlePlacesFactory from "./factories/googlePlacesFactory";

const stApp = angular
  .module("stApp", ["ngAnimate", "ngFileUpload", "ui.select"])
  .config([
    "$locationProvider",
    ($locationProvider) => {
      $locationProvider.html5Mode(true); // remove the `!` from url
      $locationProvider.hashPrefix(""); // remove the `#` from url
    },
  ])
  .filter("money_format", [
    "$filter",
    ($filter) => (money, options) => {
      if (options === undefined) {
        options = { decimals: "if-needed" };
      }
      var symbols = {
        EUR: "€",
      };
      if (symbols[money.currency.code] === undefined) {
        throw "unknown currency : " + money.currency.code;
      }
      var formattedMoney = $filter("currency")(money.amount / 100, symbols[money.currency.code]);
      if (options.decimals === "if-needed") {
        formattedMoney = formattedMoney.replace(",00", "");
      }
      return formattedMoney;
    },
  ])
  .filter("noaccentFilter", () => (items, needle) => {
    let out = [];

    if (needle === "") {
      return items;
    } else {
      if (angular.isArray(items)) {
        items.forEach((item) => {
          function removeAccents(value) {
            return value
              .replace(/á/g, "a")
              .replace(/â/g, "a")
              .replace(/é/g, "e")
              .replace(/è/g, "e")
              .replace(/ê/g, "e")
              .replace(/í/g, "i")
              .replace(/ï/g, "i")
              .replace(/ì/g, "i")
              .replace(/î/g, "i")
              .replace(/ó/g, "o")
              .replace(/ô/g, "o")
              .replace(/ú/g, "u")
              .replace(/ü/g, "u")
              .replace(/ç/g, "c");
          }

          const text = removeAccents(item.name.toLowerCase());
          const search = removeAccents(needle.toLowerCase());
          if (text.indexOf(search) > -1) {
            out.push(item);
          }
        });
      } else {
        out = items;
      }

      return out;
    }
  })
  .controller("avatarCtrl", avatarCtrl)
  .controller("profileCtrl", profileCtrl)
  .directive("gmapAutocomplete", gmapAutocomplete.directiveFactory)
  .factory("googlePlacesFactory", googlePlacesFactory.serviceFactory);

export default stApp;
