const autocompleteModule = (() => {
  const options = {
    types: ["(cities)"],
  };

  const autocompletes = [];
  const inputs = [];

  const add = (el, i) => {
    const autocomplete = new google.maps.places.Autocomplete(el, options);
    // On définit les données qu'on veut récupérer lors d'un appel : .getPlace()
    // réduction des coûts https://developers.google.com/places/web-service/details#fields
    autocomplete.setFields([
      "address_component",
      "geometry",
      "place_id",
      // on récupère les données suivantes en plus au cas où on en ai besoin un jour:
      "adr_address",
      "formatted_address",
      "name",
      "plus_code",
      "type",
      "url",
      "vicinity",
    ]);
    autocomplete.addListener("place_changed", () => {
      onPlaceChanged(i);
    });
    preventEnter(el);
    autocompletes[i] = autocomplete;
    inputs[i] = el;
    $(el).data("autocomplete-init", 1);
  };

  const onPlaceChanged = (index) => {
    const place = autocompletes[index].getPlace();

    // transform address components to get by type instead index
    const addressComponents = [];
    for (let i = 0; i < place.address_components.length; i++) {
      const addressType = place.address_components[i].types[0];
      addressComponents[addressType] = [];
      addressComponents[addressType]["long_name"] = place.address_components[i].long_name;
      addressComponents[addressType]["short_name"] = place.address_components[i].short_name;
    }

    fillData(index, place, addressComponents);
  };

  const fillData = (index, place, addressComponents) => {
    // find parent wrapper and field to fill with place data
    const $wrapper = $(inputs[index]).parents(".autocomplete-wrapper");
    if ($wrapper.length > 0) {
      const $fieldsToFill = $wrapper.find("*[data-autocomplete]");
      if ($fieldsToFill.length > 0) {
        $fieldsToFill.each((i, el) => {
          const dataToRetrieve = $(el).data("autocomplete");
          if (dataToRetrieve === "place_id") {
            $(el).val(place[dataToRetrieve]);
          } else if (dataToRetrieve === "place_data") {
            $(el).val(JSON.stringify(place));
          } else if (dataToRetrieve === "lat") {
            $(el).val(place.geometry.location.lat());
          } else if (dataToRetrieve === "lng") {
            $(el).val(place.geometry.location.lng());
          } else if (dataToRetrieve === "city") {
            // si locality n'existe pas dans cette adresse, on fallback sur administrative_area_level_3 qui correspond à la boite postale.
            let city = addressComponents["locality"]
              ? addressComponents["locality"]
              : addressComponents["administrative_area_level_3"];
            // si la boite postale n'existe pas, on fallback sur le nom de la place.
            city = null === city ? place.name : city["long_name"];
            $(el).val(city);
          } else if (dataToRetrieve === "country_code") {
            $(el).val(addressComponents["country"]["short_name"]);
          } else if (dataToRetrieve === "country_short") {
            $(el).val(addressComponents["country"]["short_name"]);
          } else if (dataToRetrieve === "administrative_area_level_1") {
            $(el).val(addressComponents["administrative_area_level_1"]["long_name"]);
          }
        });
      }
    }
  };

  const preventEnter = (input) => {
    google.maps.event.addDomListener(input, "keydown", (e) => {
      if (e.keyCode === 13) {
        e.preventDefault();
      }
    });
  };

  return {
    init: () => {
      const $inputs = $(".autocomplete");

      if ($inputs.length > 0) {
        $inputs.each((i, el) => {
          if (!$(el).attr("data-autocomplete-init")) {
            add(el, i);
          }
        });
      }
    },
    add: add,
  };
})();

export default autocompleteModule;
