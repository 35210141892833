class avatarCtrl {
  constructor($scope, $timeout, Upload) {
    $scope.uploadFiles = (file) => {
      if (file && !file.$error) {
        file.upload = Upload.upload({
          url: "/api/upload/user-avatar?w=180&h=180&fit=crop",
          data: { image: file },
        });

        file.upload.then(
          (response) => {
            $scope.avatar = response.data;
            $scope.info.avatar = $scope.avatar.path;
            $timeout(() => {
              file.result = response.data;
            });
          },
          (response) => {
            window.alert(response.data.errorMessage);
          }
        );
      }
    };
  }
}

avatarCtrl.$inject = ["$scope", "$timeout", "Upload"];
export default avatarCtrl;
