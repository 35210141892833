// Modules
import Tokenfield from "bootstrap-tokenfield/js/bootstrap-tokenfield";

const voidFunction = () => {
  $(document).ready(() => {
    if ($("#sponsorship-functions").length > 0) {
      const sharedLink = $('meta[property="og:url"]').attr("content");
      const $smsBtn = $("#sms-share");
      const $messengerBtn = $("#messenger-share");
      const $fbBtn = $("#facebook-share, #facebook-share-mobile");

      /** Share: SMS **/
      // Replace sms href to adapt to iOS and Android
      const bodySms = $smsBtn.attr("data-sms");
      $smsBtn.attr("href", window.plinks.smsLink(bodySms));

      // Get iOS version (return -> 7,1,2)
      const iOSversion = () => {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
          // supports iOS 2.0 and later: <http://bit.ly/TJjs1V>
          const v = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
          return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        }
      };
      // Hide SMS function if iOS version is less than 8
      if (iOSversion() !== undefined && iOSversion()[0] < 8) {
        $smsBtn.css("display", "none");
      }

      /** Share: Messenger **/
      $messengerBtn.on("click", (e) => {
        e.preventDefault();
        FB.ui(
          {
            method: "send",
            link: sharedLink,
          },
          // callback
          (response) => {
            if (response) {
              // alert('Partage réussi.');
            } else {
              // alert('Partage non fait.');
            }
          }
        );
      });

      /** Share: Facebook **/
      $fbBtn.on("click", (e) => {
        e.preventDefault();
        FB.ui(
          {
            method: "share",
            href: sharedLink,
          },
          // callback
          (response) => {
            if (response && !response.error_message) {
              // alert('Posting completed.');
            } else {
              // alert('Error while posting.');
            }
          }
        );
      });
    }

    /** Tokenfield (used on the sponsorship invite page to enter multiple emails) **/
    const sponsorshipEmailInvitationsForm = document.querySelector('form[name="sponsorship-send-email-invitations"]');
    if (sponsorshipEmailInvitationsForm) {
      const $emailsField = $('[data-role="tokenfield-email"]', sponsorshipEmailInvitationsForm);
      const emailsFieldTokenfield = new Tokenfield($emailsField[0], {
        delimiter: [" ", ","],
      });

      const $submitButton = $("#sendEmail", sponsorshipEmailInvitationsForm);
      const setSubmitButtonState = () => {
        if ($(".token", sponsorshipEmailInvitationsForm).not(".invalid").length > 0) {
          // at least one token is a valid email
          $submitButton.removeAttr("disabled");
        } else {
          $submitButton.attr("disabled", true);
        }
      };

      $emailsField
        .on("tokenfield:createtoken", (e) => {
          // cannot enter duplicate token
          const existingTokens = emailsFieldTokenfield.getTokens();
          $.each(existingTokens, (index, token) => {
            if (token.value === e.attrs.value) {
              e.preventDefault();

              // highlight the existing token
              const $duplicate = $(`[data-value="${e.attrs.value}"]`);
              $duplicate.addClass("duplicate");
              setTimeout(() => {
                $(".duplicate").removeClass("duplicate");
              }, 300);
            }
          });
        })
        .on("tokenfield:createdtoken", (e) => {
          // e-mail validation
          const re = /\S+@\S+\.\S+/;
          const valid = re.test(e.attrs.value);
          if (!valid) {
            $(e.relatedTarget).addClass("invalid");
          }

          setSubmitButtonState();
        })
        .on("tokenfield:removedtoken", () => {
          setSubmitButtonState();
        });

      sponsorshipEmailInvitationsForm.addEventListener("input", (e) => {
        e.target.form.classList.remove("sponsorship-email-invitations-delivered");
      });

      sponsorshipEmailInvitationsForm.addEventListener("submit", (e) => {
        e.preventDefault();
        const _self = e.target;

        _self.classList.add("sponsorship-email-invitations-delivery-in-progress");
        _self.classList.remove("sponsorship-email-invitations-delivery-failed");
        _self.classList.remove("sponsorship-email-invitations-delivered");
        $.ajax({
          type: _self.method,
          url: _self.action,
          dataType: "json",
          data: {
            emails: _self.emails.value,
          },
          success: () => {
            emailsFieldTokenfield.setTokens([]);
            setSubmitButtonState();
            _self.classList.add("sponsorship-email-invitations-delivered");
          },
          error: (e) => {
            document.querySelector(".the-sponsorship-email-invitations-delivery-failed-message").textContent =
              e.responseJSON.error;
            _self.classList.add("sponsorship-email-invitations-delivery-failed");
          },
          complete: () => {
            _self.classList.remove("sponsorship-email-invitations-delivery-in-progress");
          },
        });
      });
    }
  });
};

export default voidFunction.call(window);
