import find from "lodash/find";

class gmapAutocomplete {
  constructor(googlePlacesFactory) {
    let linkFunction = (scope, element) => {
      googlePlacesFactory.googlePlaces().then(() => {
        const options = {
          types: ["(cities)"],
        };

        const autocomplete = new google.maps.places.Autocomplete(element[0], options);

        // On définit les données qu'on veut récupérer lors d'un appel : .getPlace()
        // réduction des coûts https://developers.google.com/places/web-service/details#fields
        autocomplete.setFields([
          "address_component",
          "geometry",
          "place_id",
          // on récupère les données suivantes en plus au cas où on en ai besoin un jour:
          "adr_address",
          "formatted_address",
          "name",
          "plus_code",
          "type",
          "url",
          "vicinity",
        ]);

        google.maps.event.addListener(autocomplete, "place_changed", () => {
          const place = autocomplete.getPlace();

          // fill scope
          scope.info.userLocation.displayName = place.formatted_address;
          scope.info.userLocation.googlePlace = place;
          scope.info.userLocation.lat = place.geometry ? place.geometry.location.lat() : null;
          scope.info.userLocation.lng = place.geometry ? place.geometry.location.lng() : null;
          let city = find(place.address_components, (component) => component.types.includes("locality"));
          // si locality n'existe pas dans cette adresse, on fallback sur administrative_area_level_3 qui correspond à la boite postale.
          city =
            city !== undefined
              ? city
              : find(place.address_components, (component) => component.types.includes("administrative_area_level_3"));
          // si la boite postale n'existe pas, on fallback sur le nom de la place.
          scope.info.userLocation.city = city !== undefined ? city.long_name : place.name;
          const country = find(place.address_components, (component) => component.types.includes("country"));
          scope.info.userLocation.country = country !== undefined ? country.short_name : null;

          scope.$broadcast("GMAP_AUTOCOMPLETE.CHANGED");
        });
      });
    };

    return {
      restrict: "A",
      link: linkFunction,
    };
  }

  static directiveFactory(googlePlacesFactory) {
    return new gmapAutocomplete(googlePlacesFactory);
  }
}

gmapAutocomplete.directiveFactory.$inject = ["googlePlacesFactory"];
export default gmapAutocomplete;
