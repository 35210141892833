const voidFunction = () => {
  $(document).ready(() => {
    /** Timeline widget **/
    const $timeline = $("#timeline-widget");
    if ($timeline.length > 0) {
      const today = new Date();
      let percentage = 1.0; // 1.00 = 100%

      // on itère sur chaque date
      $timeline.find("li").each((i, el) => {
        const $self = $(el);

        // si la date passée n'a pas été terminée, on la flag comme en retard
        if (!$self.prev().hasClass("done")) {
          $self.prev().addClass("late fcr").removeClass("fcn");
        }

        $self.addClass("nextDate").prev().removeClass("nextDate"); // on défini notre position pour la retrouver après l'itération

        // si next date n'est pas défini
        if ($self.find(".date").attr("data-date") === "") {
          percentage = 0.0; // on n'a pas de de quoi comparer, la progress bar verte reste sur la date précédente.
          return false;
        }

        // si next date est dans le futur
        if (today <= new Date($self.find(".date").attr("data-date"))) {
          const start = new Date($self.prev().find(".date").attr("data-date"));
          const end = new Date($self.find(".date").attr("data-date"));

          percentage = ((today - start) / (end - start)).toFixed(2); // on défini le pourcentage de la progress bar entre les 2 dates (la dernière passée et la suivante)
          return false;
        }
      });

      // On calcule la position de today pour définir la position de la progress bar
      const prev =
        $timeline.find(".nextDate").prev().position().left + $timeline.find(".nextDate").prev().outerWidth() / 2;
      const next = $timeline.find(".nextDate").position().left + $timeline.find(".nextDate").outerWidth() / 2;

      const width = Math.round((next - prev) * percentage + prev);
      $("#timeline-done").css("width", `${width}px`);
    }
  });
};

export default voidFunction.call(window);
