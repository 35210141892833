/** @example http://photoswipe.com/ */
import PhotoSwipe from "photoswipe/dist/photoswipe.js";
import PhotoSwipeUI_Default from "photoswipe/dist/photoswipe-ui-default.js";

// init
window.addEventListener(
  "load",
  () => {
    /** 1. prepare functions **/
    // parse slide data (url, title, size ...) from DOM elements
    // (children of gallerySelector)
    const parseThumbnailElements = (el) => {
      let thumbElements = el.childNodes,
        numNodes = thumbElements.length,
        items = [],
        figureEl,
        linkEl,
        size,
        item;

      for (let i = 0; i < numNodes; i++) {
        figureEl = thumbElements[i]; // <figure> element

        // include only element nodes
        if (figureEl.nodeType !== 1) {
          continue;
        }

        linkEl = figureEl.children[0]; // <a> element

        size = linkEl.getAttribute("data-size").split("x");

        // create slide object
        item = {
          src: linkEl.getAttribute("href"),
          w: parseInt(size[0], 10),
          h: parseInt(size[1], 10),
        };

        if (figureEl.children.length > 1) {
          // <figcaption> content
          item.title = figureEl.children[1].innerHTML;
        }

        if (linkEl.children.length > 0) {
          // <img> thumbnail element, retrieving thumbnail url
          item.msrc = linkEl.children[0].getAttribute("src");
        }

        item.el = figureEl; // save link to element for getThumbBoundsFn
        items.push(item);
      }

      return items;
    };

    // find nearest parent element
    const closest = (el, fn) => {
      return el && (fn(el) ? el : closest(el.parentNode, fn));
    };

    // parse picture index and gallery index from URL (#&pid=1&gid=2)
    const photoswipeParseHash = () => {
      const hash = window.location.hash.substring(1);
      const params = {};

      if (hash.length < 5) {
        return params;
      }

      let vars = hash.split("&");

      for (let i = 0; i < vars.length; i++) {
        if (!vars[i]) {
          continue;
        }

        const pair = vars[i].split("=");

        if (pair.length < 2) {
          continue;
        }

        params[pair[0]] = pair[1];
      }

      if (params.gid) {
        params.gid = parseInt(params.gid, 10);
      }

      return params;
    };

    /**
     * @param index
     * @param galleryElement element|string (gallery selector or element: accept data-items json payload div OR images' list container div)
     * @param fromURL
     */
    const openPhotoSwipe = (index, galleryElement, fromURL) => {
      if (typeof galleryElement === "string") {
        galleryElement = document.querySelector(galleryElement);
      }
      const isDomGallery = galleryElement.getAttribute("data-items") === null;

      let items;
      if (isDomGallery === true) {
        items = parseThumbnailElements(galleryElement);
      } else {
        items = JSON.parse(galleryElement.getAttribute("data-items"));
      }

      // define options (if needed)
      const options = {
        // define gallery index (for URL)
        galleryUID: galleryElement.getAttribute("data-pswp-uid"),

        getThumbBoundsFn: (index) => {
          if (isDomGallery === false) {
            return;
          }

          // See Options -> getThumbBoundsFn section of documentation for more info
          let thumbnail = items[index].el.getElementsByTagName("img")[0], // find thumbnail
            pageYScroll = window.pageYOffset || document.documentElement.scrollTop,
            rect = thumbnail.getBoundingClientRect();

          return { x: rect.left, y: rect.top + pageYScroll, w: rect.width };
        },
      };

      // PhotoSwipe opened from URL
      if (fromURL) {
        options.showAnimationDuration = 0;

        if (options.galleryPIDs) {
          // parse real index when custom PIDs are used
          // http://photoswipe.com/documentation/faq.html#custom-pid-in-url
          for (let j = 0; j < items.length; j++) {
            if (parseInt(items[j].pid, 10) === parseInt(index, 10)) {
              options.index = j;
              break;
            }
          }
        } else {
          // in URL indexes start from 1
          options.index = parseInt(index, 10) - 1;
        }
      } else {
        options.index = parseInt(index, 10);
      }

      // exit if index not found
      if (isNaN(options.index)) {
        return;
      }

      const gallery = new PhotoSwipe(document.querySelectorAll(".pswp")[0], PhotoSwipeUI_Default, items, options);

      if (isDomGallery === false) {
        // create variable that will store real size of viewport
        let realViewportWidth,
          useLargeImages = false,
          firstResize = true,
          imageSrcWillChange;

        // beforeResize event fires each time size of gallery viewport updates
        gallery.listen("beforeResize", () => {
          // gallery.viewportSize.x - width of PhotoSwipe viewport
          // gallery.viewportSize.y - height of PhotoSwipe viewport
          // window.devicePixelRatio - ratio between physical pixels and device independent pixels (Number)
          //                          1 (regular display), 2 (@2x, retina) ...

          // calculate real pixels when size changes
          realViewportWidth = gallery.viewportSize.x * window.devicePixelRatio;

          // Code below is needed if you want image to switch dynamically on window.resize

          // Find out if current images need to be changed
          if (useLargeImages && realViewportWidth < 768) {
            useLargeImages = false;
            imageSrcWillChange = true;
          } else if (!useLargeImages && realViewportWidth >= 768) {
            useLargeImages = true;
            imageSrcWillChange = true;
          }

          // Invalidate items only when source is changed and when it's not the first update
          if (imageSrcWillChange && !firstResize) {
            // invalidateCurrItems sets a flag on slides that are in DOM,
            // which will force update of content (image) on window.resize.
            gallery.invalidateCurrItems();
          }

          if (firstResize) {
            firstResize = false;
          }

          imageSrcWillChange = false;
        });

        // gettingData event fires each time PhotoSwipe retrieves image source & size
        gallery.listen("gettingData", (index, item) => {
          // Set image source & size based on real viewport width
          if (useLargeImages && Object.prototype.hasOwnProperty.call(item, "desktop")) {
            item.src = item.desktop.src;
            item.w = item.desktop.w;
            item.h = item.desktop.h;
            item.title = item.desktop.title;
          } else if (Object.prototype.hasOwnProperty.call(item, "mobile")) {
            item.src = item.mobile.src;
            item.w = item.mobile.w;
            item.h = item.mobile.h;
            item.title = item.mobile.title;
          }

          // It doesn't really matter what will you do here,
          // as long as item.src, item.w and item.h have valid values.
          //
          // Just avoid http requests in this listener, as it fires quite often
        });
      }

      gallery.init();
    };

    // triggers when user clicks on thumbnail
    const onThumbnailsClick = (e) => {
      e = e || window.event;
      e.preventDefault ? e.preventDefault() : (e.returnValue = false);

      const eTarget = e.target || e.srcElement;

      // find root element of slide
      const clickedListItem = closest(eTarget, (el) => {
        return el.tagName && el.tagName.toUpperCase() === "FIGURE";
      });

      if (!clickedListItem) {
        return;
      }

      // find index of clicked item by looping through all child nodes
      // alternatively, you may define index via data- attribute
      let clickedGallery = clickedListItem.parentNode,
        childNodes = clickedListItem.parentNode.childNodes,
        numChildNodes = childNodes.length,
        nodeIndex = 0,
        index;

      for (let i = 0; i < numChildNodes; i++) {
        if (childNodes[i].nodeType !== 1) {
          continue;
        }

        if (childNodes[i] === clickedListItem) {
          index = nodeIndex;
          break;
        }
        nodeIndex++;
      }

      if (index >= 0) {
        // open PhotoSwipe if valid index found
        openPhotoSwipe(index, clickedGallery);
      }
      return false;
    };

    /** 2. execute functions **/
    const elements = document.querySelectorAll("[data-open-photo-swipe]");
    if (elements.length > 0) {
      document.addEventListener("click", (e) => {
        e = e || window.event;
        const target = e.target || e.srcElement;
        const element = target.closest("[data-open-photo-swipe]");

        if (element === null) {
          return;
        }

        const data = JSON.parse(element.dataset["openPhotoSwipe"]);
        openPhotoSwipe(data.index, data.selector);
      });
    }

    // loop through all gallery elements and bind events
    const galleryElements = document.querySelectorAll(".photoswipe-gallery");

    let uid = 1;
    for (let i = 0, l = galleryElements.length; i < l; i++) {
      while (document.querySelector(`[data-pswp-uid="${uid}"]`) !== null) {
        uid++;
      }

      if (galleryElements[i].getAttribute("data-pswp-uid") === null) {
        galleryElements[i].setAttribute("data-pswp-uid", uid);
      }

      if (galleryElements[i].getAttribute("data-items") === null) {
        galleryElements[i].onclick = onThumbnailsClick;
      }
    }

    // Parse URL and open gallery if it contains #&pid=3&gid=1
    const hashData = photoswipeParseHash();
    if (hashData.pid && hashData.gid) {
      openPhotoSwipe(hashData.pid, galleryElements[hashData.gid - 1], true);
    }
  },
  false
);
