const voidFunction = () => {
  $(document).ready(() => {
    const $button = $(".download-file-button");
    if ($button.length > 0) {
      const setCookie = (name, value, expiracy) => {
        const exdate = new Date();
        exdate.setTime(exdate.getTime() + expiracy * 1000);
        const c_value = escape(value) + (expiracy == null ? "" : "; expires=" + exdate.toUTCString());
        document.cookie = name + "=" + c_value + "; path=/";
      };

      const getCookie = (name) => {
        let i,
          x,
          y,
          ARRcookies = document.cookie.split(";");
        for (i = 0; i < ARRcookies.length; i++) {
          x = ARRcookies[i].substr(0, ARRcookies[i].indexOf("="));
          y = ARRcookies[i].substr(ARRcookies[i].indexOf("=") + 1);
          x = x.replace(/^\s+|\s+$/g, "");
          if (x === name) {
            return y ? decodeURI(unescape(y.replace(/\+/g, " "))) : y; //;//unescape(decodeURI(y));
          }
        }
      };

      var checkDownloadCookie = () => {
        if (getCookie("downloadStarted") === "1") {
          setCookie("downloadStarted", "false", 100); //Expiration could be anything... As long as we reset the value
          $button.removeClass("disabled is-loading");
        } else {
          setTimeout(checkDownloadCookie, 1000); //Re-run this function in 1 second.
        }
      };

      $button.on("click", (e) => {
        $button.addClass("disabled"); // le cookie n'est pas unique à chaque bouton, on ne peut donc pas avoir plusieurs boutons téléchager avec loader sur la même page (il faudrait faire évoluer ce code pour cela). En attendant, on désactive les autres boutons pour n'avoir qu'un téléchargement par page au même moment.
        $(e.target).addClass("is-loading");
        setCookie("downloadStarted", 0, 100); //Expiration could be anything... As long as we reset the value
        setTimeout(checkDownloadCookie, 1000); //Initiate the loop to check the cookie.
      });
    }
  });
};

export default voidFunction.call(window);
