// Modules
import "mresize";

const voidFunction = () => {
  $(document).ready(() => {
    /** Aside follow scroll **/
    const $asideContainer = $("#follow_scroll_container");
    if ($asideContainer.length > 0) {
      const $window = $(window);
      const $main = $("#main");
      const $asideFixed = $("#follow_scroll--fixed");
      const $asideMain = $("#follow_scroll--main");
      let lastScreenTop = 0;

      // 1. stop the navbar animation
      $("nav.navbar").addClass("disable-animation");

      // 2. prepare reusable functions
      const resize = () => {
        if (window.matchMedia("(min-width: 992px)").matches) $asideContainer.addClass("active");
        else $asideContainer.removeClass("active");
      };

      const scrolling = (isInitializing = false) => {
        let screenTop = $window.scrollTop(),
          screenHeight = $window.height(),
          screenBottom = screenTop + screenHeight,
          mainTop = $main.offset().top,
          mainHeight = $main.height(),
          mainBottom = mainTop + mainHeight,
          asideContainerTop = $asideContainer.offset().top,
          asideContainerHeight = mainBottom - asideContainerTop,
          // asideContainerBottom    = mainBottom,

          asideFixedTop = $asideFixed.offset().top,
          asideFixedHeight = $asideFixed.outerHeight(true),
          asideFixedBottom = asideFixedTop + asideFixedHeight,
          asideMainTop = $asideMain.offset().top,
          asideMainHeight = $asideMain.outerHeight(true),
          asideMainBottom = asideMainTop + asideMainHeight,
          asideWidth = $asideContainer.outerWidth(true),
          asideSpace = asideContainerHeight - asideFixedHeight,
          asideRight = parseFloat($window.width() - ($asideContainer.offset().left + asideWidth));

        const setAsideFixedTop = (asideWidth, asideRight) => {
          $asideFixed.addClass("fixed").removeClass("bottom").css({ top: 0, width: asideWidth, right: asideRight });
        };

        const setAsideFixedBottom = (asideSpace) => {
          $asideFixed.addClass("fixed bottom").css({ top: asideSpace, width: "", right: "" });
        };

        const setAsideMainTop = (asideFixedHeight, asideWidth, asideRight) => {
          $asideMain.addClass("top").removeClass("absolute bottom").css({
            top: asideFixedHeight,
            width: asideWidth,
            right: asideRight,
          });
        };

        const setAsideMainAbsolute = (topFromContainer) => {
          $asideMain.addClass("absolute").removeClass("top bottom").css({
            top: topFromContainer,
            width: "",
            right: "",
          });
        };

        const setAsideMainBottom = (asideWidth, asideRight) => {
          $asideMain.addClass("bottom").removeClass("absolute top").css({
            top: "",
            width: asideWidth,
            right: asideRight,
          });
        };

        const resetAside = () => {
          $asideFixed.removeClass("fixed bottom").removeAttr("style");
          $asideMain.removeClass("absolute top bottom").removeAttr("style");
        };

        const scrollToTop = screenTop <= lastScreenTop; // true = upscroll || false = downscroll

        lastScreenTop = screenTop;

        if ($asideContainer.hasClass("active") && mainHeight > asideFixedHeight + asideMainHeight) {
          /* je suis en version desktop ET le main est plus grand que l'élément scrollable "aside" => activer la fonction */

          if (screenTop > mainBottom) {
            /* je suis en dessous de aside : le haut de mon écran est plus bas que le bas de mon aside */

            // asideFixed collé en bas
            setAsideFixedBottom(asideSpace);

            // asideMain collé en bas
            setAsideMainAbsolute(asideContainerHeight - asideMainHeight);
          } else if (screenTop > asideContainerTop) {
            /* je suis dans aside : le haut de mon écran est plus bas que le haut de aside et plus haut que le bas de aside */

            if (scrollToTop && !isInitializing) {
              /* je monte (↑) */

              // asideFixed
              if (screenTop <= asideFixedTop) {
                /* ↑ le haut de mon écran touche le haut de asideFixed */
                setAsideFixedTop(asideWidth, asideRight);
              }

              // asideMain
              if (Math.round(asideMainTop) >= Math.round(asideFixedBottom)) {
                /* ↑ le haut de asideMain est visible (donc collé sous le asideFixed) */
                setAsideMainTop(asideFixedHeight, asideWidth, asideRight);
              } else {
                /* ↑ le haut de asideMain est caché (donc scroll sur asideMain) */
                setAsideMainAbsolute(asideMainTop - asideContainerTop);
              }
            } else {
              /* je descend (↓) ou je positionne les blocs pour la première fois */

              // asideFixed
              if (Math.round(asideFixedBottom) >= Math.round(mainBottom)) {
                /* ↓ le bas de asideFixed touche le bas du main */
                setAsideFixedBottom(asideSpace);
              } else {
                /* ↓ le bas de asideFixed NE touche PAS le bas du main */
                setAsideFixedTop(asideWidth, asideRight);
              }

              // asideMain
              if (Math.round(asideMainBottom) >= Math.round(mainBottom)) {
                /* ↓ le bas de asideMain touche le bas du main */
                setAsideMainAbsolute(asideContainerHeight - asideMainHeight);
              } else if (
                Math.round(asideMainBottom) <= Math.round(screenBottom) &&
                Math.round(asideMainTop) >= Math.round(asideFixedBottom)
              ) {
                /* ↓ le haut et le bas de asideMain sont visibles (donc collé sous le asideFixed) */
                setAsideMainTop(asideFixedHeight, asideWidth, asideRight);
              } else if (Math.round(asideMainBottom) <= Math.round(screenBottom)) {
                /* ↓ le bas de asideMain est visible (donc collé au bas de l'écran) */
                setAsideMainBottom(asideWidth, asideRight);
              } else {
                /* ↓ le bas de asideMain est caché (donc scroll sur asideMain) */
                setAsideMainAbsolute(asideMainTop - asideContainerTop);
              }
            }
          } else {
            /* je suis au dessus de aside : le haut de mon écran est plus haut que le haut de aside */
            resetAside();
          }
        } else {
          /* le main est plus petit que l'élément scrollable OU je suis en mobile = désactiver la fonction */
          resetAside();
        }
      };

      // 3. activate functions
      /* initialize */
      resize();
      scrolling(true);
      scrolling(true); // execute 2 times to be sure blocs are positioned

      $window.on("resize", () => {
        /* screen size changed */
        resize();
        scrolling(true);
      });

      $main.on("mresize", () => {
        /* main height changed (use the mresize plugin) */
        scrolling(true);
        scrolling(true); // execute 2 times to be sure blocs are positioned
      });

      $window.on("scroll", () => {
        /* user scrolled */
        scrolling();
      });
    }
  });
};

export default voidFunction.call(window);
