// Vendors imports
import "../vendor/plinks";

const voidFunction = () => {
  $(document).ready(() => {
    /** Offer details: count open blocks **/
    const $details = $(".offer-details");
    if ($details.length > 0) {
      const blockOpen = ($this) => {
        $.ajax({
          type: $this.attr("data-method"),
          url: $this.attr("data-action"),
          dataType: "json",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + $this.attr("data-token"),
          },
          data: JSON.stringify({
            title: $this.prev(".panel-heading").find(".blockTitle").text(),
          }),
        });
      };
      const $blockCollapse = $(".block-collapse");

      blockOpen($blockCollapse.first());

      $blockCollapse.on("shown.bs.collapse", (e) => {
        blockOpen($(e.target));
      });
    }

    /** Share functions **/
    const $fixedBottom = $(".fixed-bottom");
    if ($fixedBottom.length > 0) {
      window.fixedBottomElements = [".admin-bar", ".fixed-action", "#Snapcall_Cadre"];
      window.repositionFixedBottomElements = () => {
        const bottomHeight = $fixedBottom.outerHeight(true);
        if (bottomHeight > 0) {
          // reposition depending of fixed bottom element
          window.fixedBottomElements.forEach((element) => {
            const $element = $(element);
            if ($element.length > 0 && $element.is(":visible")) {
              const elementBottom =
                parseInt($element.attr("data-initial-bottom")) ||
                $(window).height() - ($element.position().top + $element.outerHeight(true));
              $element.attr("data-initial-bottom", elementBottom);
              $element.css("bottom", elementBottom + bottomHeight);
            }
          });
        } else {
          // restore default position
          window.fixedBottomElements.forEach((element) => {
            const $element = $(element);
            if ($element.length > 0 && $element.attr("data-initial-bottom")) {
              $element.css("bottom", parseInt($element.attr("data-initial-bottom")));
              $element.removeAttr("data-initial-bottom");
            }
          });
        }
      };

      // on fait un premier passage
      window.repositionFixedBottomElements();

      // on supporte les éléments ajoutés dynamiquement après rendu initial du dom
      document.addEventListener(
        "DOMNodeInserted",
        (e) => {
          if ($(e.target).is(window.fixedBottomElements.join(", "))) {
            window.repositionFixedBottomElements();
          }
        },
        false
      );

      // on recalcule à chaque changement de largeur d'écran
      $(window).on("resize", () => {
        window.repositionFixedBottomElements();
      });
    }
  });
};

export default voidFunction.call(window);
