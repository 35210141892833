// Modules
import moment from "moment-timezone";

const voidFunction = () => {
  $(document).ready(() => {
    /** Create programme alert **/
    const $createAlert = $(".createAlert");
    if ($createAlert.length > 0) {
      // Modal is now opened
      $createAlert.on("click", (e) => {
        const $this = $(e.target);
        const $modal = $($this.attr("data-target"));

        $modal.addClass("is-loading").removeClass("success error");

        const data = {
          desiredTravelDate: $this.data("date")
            ? moment($this.data("date")).format("YYYY-MM-DDTHH:mm:ss.SSSSSSZ")
            : null,
          desiredTravelDateFlexibility: $this.data("date-flexibility")
            ? parseInt($this.data("date-flexibility"))
            : null,
        };

        if ($this.data("programme") !== undefined) {
          data["desiredProgrammeId"] = $this.data("programme");
        }

        if ($this.data("destination") !== undefined) {
          data["desiredDestination"] = $this.data("destination");
        }

        $.ajax({
          type: $this.data("method"),
          url: $this.data("action"),
          dataType: "json",
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + $this.data("token"),
          },
          data: JSON.stringify(data),
          success: () => {
            $modal.removeClass("is-loading").addClass("success");

            // Changed initial button
            $this.addClass("hidden").next().removeClass("hidden");
          },
          error: () => {
            $modal.removeClass("is-loading").addClass("error");
          },
        });
      });
    }
  });
};

export default voidFunction.call(window);
