// Modules
import StackBlur from "stackblur-canvas";

const voidFunction = () => {
  $(document).ready(() => {
    /** Header canvas as background cover **/
    const $elem = $("#profileCanvasBlurred");
    const $containerElem = $(".bg-image");

    const imageAsBackgroundCover = () => {
      const containerHeight = $containerElem.height();
      const containerWidth = $containerElem.width();
      const elemHeight = $elem.height();
      const elemWidth = $elem.width();

      // if image is vertically smaller than container
      if (elemHeight <= containerHeight && elemWidth > containerWidth) {
        $elem.addClass("wh").removeClass("ww"); // set height 100%
      } else {
        $elem.addClass("ww").removeClass("wh"); // set width 100%
      }
    };

    if ($elem.length > 0) {
      // execute the StackBlur plugin
      const sourceImage = document.getElementById("profileImageBlurred");
      const targetCanvas = document.getElementById("profileCanvasBlurred");
      sourceImage.onload = () => {
        StackBlur.image(sourceImage, targetCanvas, 30, false);
      };

      // set the ratio type class
      imageAsBackgroundCover();

      $(window).resize(() => {
        imageAsBackgroundCover();
      });
    }
  });
};

export default voidFunction.call(window);
