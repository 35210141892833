class profileCtrl {
  constructor($scope) {
    const $pastCountriesHolder = angular.element("#order_pastCountries");
    $pastCountriesHolder.data("index", $pastCountriesHolder.find(":input").length);

    const $nextCountriesHolder = angular.element("#order_nextCountries");
    $nextCountriesHolder.data("index", $nextCountriesHolder.find(":input").length);

    $scope.onPastCountriesRemove = ($item) => {
      removeCountry($pastCountriesHolder, $item);
    };

    $scope.onPastCountriesSelect = ($item) => {
      addCountry($pastCountriesHolder, $item);
    };

    $scope.onNextCountriesRemove = ($item) => {
      removeCountry($nextCountriesHolder, $item);
    };

    $scope.onNextCountriesSelect = ($item) => {
      addCountry($nextCountriesHolder, $item);
    };

    const addCountry = ($collectionHolder, $item) => {
      let prototype = $collectionHolder.data("prototype");
      let index = $collectionHolder.data("index");
      const $newCountry = angular.element(prototype.replace(/__name__/g, index));
      $newCountry.val($item.id);

      $collectionHolder.append($newCountry);
      $collectionHolder.data("index", index + 1);
    };

    const removeCountry = ($collectionHolder, $item) => {
      $collectionHolder
        .find(":input")
        .filter((index, el) => angular.element(el).val() === $item.id)
        .remove();
    };
  }
}

profileCtrl.$inject = ["$scope"];
export default profileCtrl;
